import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import notification from '../public/static/locales/en/notification.json';
import validation from '../public/static/locales/en/validation.json';
import translation from '../public/static/locales/en/translation.json';

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    resources: {
      en: {
        notif: notification,
        validation,
        translation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
