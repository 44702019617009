import cookie from 'js-cookie'

function getToken() {
  return cookie.get('token')
}
function setToken(token) {
  return cookie.set('token', token)
}

function removeToken() {
  return cookie.remove('token')
}

function setCookie(name, value) {
  return cookie.set(name, value)
}

function getCookie(name) {
  return cookie.get(name)
}

function setLocalStorage(name, value) {
  return window.localStorage.setItem(name, value)
}

function getLocalStorage(name) {
  return window.localStorage.getItem(name)
}

export {
  getToken,
  setToken,
  removeToken,
  setLocalStorage,
  getLocalStorage,
  getCookie,
  setCookie,
}
