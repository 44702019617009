import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0F4C8F',
    },
    secondary: {
      main: '#DADADA',
    },
    background: {
      default: '#E7EEF4',
    },
    text: {
      primary: '#353535',
      secondary: '#8E8E93',
    },
    error: {
      main: '#EC3C3C'
    },
    success: {
      main: '#13953F'
    },
    warning: {
      main: '#E2B207'
    }
  },
  typography: {
    fontFamily: ['Outfit', 'sans-serif'].join(','),
    h6: {
      fontSize: '20px',
      '@media (max-width: 600px)': {
        fontSize: '18px'
      }
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        borderColor: '#0F4C8F',
        backgroundColor: '#0F4C8F',
        borderRadius: '50px',
        textTransform: 'capitalize',
        color: '#fff',
        padding: "10px 5vh",
        fontSize: '15px',
        fontWeight: '500',
        transition: 'all .3s ease-out',
        '&:hover': {
          borderColor: '#0F4C8F',
          // backgroundColor: '#0F4C8F !important',
          borderRadius: '50px',
          boxShadow: 'none !important',
          filter: 'brightness(0.93)'
        },
        '@media (max-width: 600px)': {
          padding: "10px 3vh",
          fontSize: '14px'
        }
      },
    },
    MuiTabs: {
      root: {
        borderBottom: '1px solid #eee',
        // minHeight:'auto',
        textTransform: 'capitalize',
      },
      indicator: {
        backgroundColor: '#0F4C8F',
      },

    },
    MuiTab: {
      root: {
        textTransform: 'capitalize',
        // borderBottom: '1px solid #eee',
        '&:hover': {
          borderBottom: '1px solid #0F4C8F',
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '10px',
        '@media (max-width: 600px)': {
          '& .MuiBox-root ': {
            // padding: '12px',
          }
        }
      },
    },
    MuiPaper: {
      root: {
        boxShadow: 'none',
        borderRadius: 0,
      },
      elevation1: {
        boxShadow: 'none',
      },
      elevation2: {
        boxShadow: 'none',
      },
      elevation3: {
        boxShadow: 'none',
      },
    },
    MuiPaginationItem: {
      root: {
        '&.MuiPaginationItem-page.Mui-disabled': {
          backgroundColor: '#fff !important'
        },
        '&.MuiPaginationItem-page.Mui-selected': {
          backgroundColor: '#E7EEF4',
          border: '1px solid #ccc'
        },
        '&.MuiPaginationItem-root': {
          '@media (max-width: 600px)': {
            width: '28px',
            height: '28px',
            minWidth: '28px',
            margin: '0 2px'
          }
        }
      },
    },
    MuiPagination: {
      ul: {
        '&.MuiPagination-ul > li:first-child .MuiPaginationItem-page': {
          borderRadius: '50px',
          backgroundColor: '#E7EEF4 ',
          border: '1px solid #ccc'
        },
        '&.MuiPagination-ul > li:last-child .MuiPaginationItem-page': {
          borderRadius: '50px',
          backgroundColor: '#E7EEF4',
          border: '1px solid #ccc'
        },
      },
    },
    MuiSkeleton: {
      root: {
        borderRadius: '6px',
        animation: 'wave',
      },
    },
  },
});

export default theme;
