import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {removeSnackbar} from 'store/actions';
import {useTranslation} from 'react-i18next';

let displayed = [];

const Notifier = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const notifications = useSelector((store) => store.notifications || []);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const networkNotifKey = 'network-notif-key';

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const handleNetworkOnline = () => {
    closeSnackbar(networkNotifKey);
  };

  const handleNetworkOffline = () => {
    enqueueSnackbar(t('notif:networkErrorPleaseCheckYourInternetConnection'), {
      key: networkNotifKey,
      variant: 'error',
      persist: true,
      preventDuplicate: true,
    });
  };

  React.useEffect(() => {
    notifications.forEach(({key, message, options = {}, dismissed = false}) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  React.useEffect(() => {
    window.addEventListener('online', handleNetworkOnline);
    window.addEventListener('offline', handleNetworkOffline);

    return () => {
      window.removeEventListener('online', handleNetworkOnline);
      window.removeEventListener('offline', handleNetworkOffline);
    };
  }, []);

  return null;
};

export default Notifier;
