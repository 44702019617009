export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = `CLOSE_SNACKBAR`
export const REMOVE_SNACKBAR = `REMOVE_SNACKBAR`

export const LOGIN_ACTION = 'LOGIN_ACTION'

export const LOG_OUT_ACTION = 'LOG_OUT_ACTION'

export const LOGIN_ACTION_SUCCESS = `${LOGIN_ACTION}_SUCCESS`
export const LOGIN_ACTION_FAILED = `${LOGIN_ACTION}_FAILED`

export const REGISTER_ACTION = 'REGISTER_ACTION'
export const REGISTER_ACTION_SUCCESS = `${REGISTER_ACTION}_SUCCESS`
export const REGISTER_ACTION_FAILED = `${REGISTER_ACTION}_FAILED`

export const VERIFY_EMAIL_ACTION = 'VERIFY_EMAIL_ACTION'
export const VERIFY_EMAIL_ACTION_SUCCESS = `${VERIFY_EMAIL_ACTION}_SUCCESS`
export const VERIFY_EMAIL_ACTION_FAILED = `${VERIFY_EMAIL_ACTION}_FAILED`

export const FORGET_PASSWORD_ACTION = 'FORGET_PASSWORD_ACTION'
export const FORGET_PASSWORD_ACTION_SUCCESS = `${FORGET_PASSWORD_ACTION}_SUCCESS`
export const FORGET_PASSWORD_ACTION_FAILED = `${FORGET_PASSWORD_ACTION}_FAILED`

export const RESET_PASSWORD_ACTION = 'RESET_PASSWORD_ACTION'
export const RESET_PASSWORD_ACTION_SUCCESS = `${RESET_PASSWORD_ACTION}_SUCCESS`
export const RESET_PASSWORD_ACTION_FAILED = `${RESET_PASSWORD_ACTION}_FAILED`

export const GET_PRICING_ACTION = 'GET_PRICING_ACTION'
export const GET_PRICING_ACTION_SUCCESS = `${GET_PRICING_ACTION}_SUCCESS`
export const GET_PRICING_ACTION_FAILED = `${GET_PRICING_ACTION}_FAILED`

export const GET_PROFILE_ACTION = 'GET_PROFILE_ACTION'
export const GET_PROFILE_ACTION_SUCCESS = `${GET_PROFILE_ACTION}_SUCCESS`
export const GET_PROFILE_ACTION_FAILED = `${GET_PROFILE_ACTION}_FAILED`

export const GET_QUESTION_TYPES_CATEGORIZED_ACTION =
  'GET_QUESTION_TYPES_CATEGORIZED_ACTION'
export const GET_QUESTION_TYPES_CATEGORIZED_ACTION_SUCCESS = `${GET_QUESTION_TYPES_CATEGORIZED_ACTION}_SUCCESS`
export const GET_QUESTION_TYPES_CATEGORIZED_ACTION_FAILED = `${GET_QUESTION_TYPES_CATEGORIZED_ACTION}_FAILED`

export const GET_QUESTIONS_FOR_CATEGORY_ACTION =
  'GET_QUESTIONS_FOR_CATEGORY_ACTION'
export const GET_QUESTIONS_FOR_CATEGORY_ACTION_SUCCESS = `${GET_QUESTIONS_FOR_CATEGORY_ACTION}_SUCCESS`
export const GET_QUESTIONS_FOR_CATEGORY_ACTION_FAILED = `${GET_QUESTIONS_FOR_CATEGORY_ACTION}_FAILED`

export const GET_TYPE_QUESTIONS_ACTION = 'GET_TYPE_QUESTIONS_ACTION'
export const GET_TYPE_QUESTIONS_ACTION_SUCCESS = `${GET_TYPE_QUESTIONS_ACTION}_SUCCESS`
export const GET_TYPE_QUESTIONS_ACTION_FAILED = `${GET_TYPE_QUESTIONS_ACTION}_FAILED`

export const GET_QUESTION_ACTION = 'GET_QUESTION_ACTION'
export const GET_QUESTION_ACTION_SUCCESS = `${GET_QUESTION_ACTION}_SUCCESS`
export const GET_QUESTION_ACTION_FAILED = `${GET_QUESTION_ACTION}_FAILED`

export const GET_COMMENTS_ACTIONS = 'GET_COMMENTS_ACTIONS'
export const GET_COMMENTS_ACTIONS_SUCCESS = `${GET_COMMENTS_ACTIONS}_SUCCESS`
export const GET_COMMENTS_ACTIONS_FAILED = `${GET_COMMENTS_ACTIONS}_FAILED`

export const ANSWER_QUESTION_ACTION = 'ANSWER_QUESTION_ACTION'
export const ANSWER_QUESTION_ACTION_SUCCESS = `${ANSWER_QUESTION_ACTION}_SUCCESS`
export const ANSWER_QUESTION_ACTION_FAILED = `${ANSWER_QUESTION_ACTION}_FAILED`

export const NEW_COMMENT_ACTION = 'NEW_COMMENT_ACTION'
export const NEW_COMMENT_ACTION_SUCCESS = `${NEW_COMMENT_ACTION}_SUCCESS`
export const NEW_COMMENT_ACTION_FAILED = `${NEW_COMMENT_ACTION}_FAILED`

export const UPDATE_PROFILE_ACTION = 'UPDATE_PROFILE_ACTION'
export const UPDATE_PROFILE_ACTION_SUCCESS = `${UPDATE_PROFILE_ACTION}_SUCCESS`
export const UPDATE_PROFILE_ACTION_FAILED = `${UPDATE_PROFILE_ACTION}_FAILED`

export const REDO_QUESTION_ACTION = 'REDO_QUESTION_ACTION'

export const UPDATE_PASSWORD_ACTION = 'UPDATE_PASSWORD_ACTION'

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key
  return {
    type: ENQUEUE_SNACKBAR,
    payload: {
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    },
  }
}
export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
})
export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
})

export const verifyEmail = ({ email, token }) => ({
  type: VERIFY_EMAIL_ACTION,
  payload: {
    email,
    token,
  },
})

export const login = ({ email, password }) => ({
  type: LOGIN_ACTION,
  payload: {
    email,
    password,
  },
})

export const forgetPassword = ({ email }) => ({
  type: FORGET_PASSWORD_ACTION,
  payload: {
    email,
  },
})

export const resetPassword = ({ email, password, verificationCode }) => ({
  type: RESET_PASSWORD_ACTION,
  payload: {
    email,
    password,
    verificationCode,
  },
})

export const getPricing = () => ({
  type: GET_PRICING_ACTION,
})

export const getProfile = ({ token }) => ({
  type: GET_PROFILE_ACTION,
  payload: { token },
})

export const getQuestionTypesCategorized = () => ({
  type: GET_QUESTION_TYPES_CATEGORIZED_ACTION,
})

/*
export const getQuestionsForCategory = ({ categoryId }) => ({
  type: GET_QUESTION_TYPES_CATEGORIZED_ACTION,
})
*/

/* NEEDS REFACTOR */
export const getTypeQuestions = ({ typeId, data }) => ({
  type: GET_TYPE_QUESTIONS_ACTION,
  payload: { typeId, data },
})

export const getQuestion = ({ id }) => ({
  type: GET_QUESTION_ACTION,
  payload: { id },
})

export const getComments = ({ id }) => ({
  type: GET_COMMENTS_ACTIONS,
  payload: { id },
})
export const newComment = ({ id, comment, userId, parentId }) => ({
  type: NEW_COMMENT_ACTION,
  payload: { id, comment, userId, parentId },
})

export const logout = () => ({
  type: LOG_OUT_ACTION,
})

export const answerQuestion = ({
  questionId,
  userId,
  answer,
  questionType,
}) => ({
  type: ANSWER_QUESTION_ACTION,
  payload: { questionId, userId, answer, questionType },
})

export const redoQuestion = () => ({
  type: REDO_QUESTION_ACTION,
})

export const updateProfile = ({ name }) => ({
  type: UPDATE_PROFILE_ACTION,
  payload: { name },
})

export const updatePassword = ({newPassword,currentPassword}) => ({
  type: UPDATE_PASSWORD_ACTION,
  payload: {newPassword,currentPassword}
})
